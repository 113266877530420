import NewsService from '@/services/news.service';

export const state = {
  newsList: []
};

export const mutations = {
  SET_NEWS(state, news) {
    state.newsList = news;
  },
  ADD_NEWS(state, newsItem) {
    state.newsList.unshift(newsItem);
  },
  EDIT_NEWS(state, newsItem) {
    const index = state.newsList.findIndex(n => n.id === newsItem.id);
    if (index !== -1) state.newsList[index] = { ...state.newsList[index], ...newsItem };
  },
  DELETE_NEWS(state, id) {
    state.newsList = state.newsList.filter(n => n.id !== id);
  }
};

export const actions = {
  async getAllNews({ commit }) {
    const response = await NewsService.getAll();
    commit('SET_NEWS', response.data);
  },
  async addNews({ commit }, newsItem) {
    const response = await NewsService.add(newsItem);
    if (response.status === 200 || response.status === 204) {
      commit('ADD_NEWS', response.data);
    }
  },
  async editNews({ commit }, newsItem) {
    const response = await NewsService.edit(newsItem.id, newsItem);
    if (response.status === 200 || response.status === 204) {
      commit('EDIT_NEWS', newsItem);
    }
  },
  async deleteNews({ commit }, id) {
    const response = await NewsService.delete(id);
    if (response.status === 200 || response.status === 204) {
      commit('DELETE_NEWS', id);
    }
  }
};

export const getters = {
  newsList: state => state.newsList
};
