import UserService from '@/services/user.service';

export const state = {
  onlineUsers: [],
  users: [],
  devices: [],
};

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_DEVICES(state, devices) {
    state.devices = devices;
  },
};

export const actions = {

  async getAllUsers({ commit }) {
      const users = await UserService.getAll();
      commit('SET_USERS', users);
  },

  async getAllDevices({ commit }) {
    const devices = await UserService.getAllDevices();
    commit('SET_DEVICES', devices);
},

};

export const getters = {
  userList: (state) => {
    return state.users
  },
  usersWithDevices: (state) => {
    return state.users.filter(user => 
        state.devices.some(device => device.user_id === user.user_id)
    );

  },
};
