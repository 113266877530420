import api from '@/api';

class NewsService {
  getAll() {
    return api.get('/news');
  }

  get(id) {
    return api.get(`/news/${id}`);
  }

  add(news) {
    return api.post('/news', news);
  }

  edit(id, news) {
    return api.put(`/news/${id}`, news);
  }

  delete(id) {
    return api.delete(`/news/${id}`);
  }
}

export default new NewsService();
